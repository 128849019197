<template>
  <div id="limit-numbers" class="container">

    <div v-if="isCustomPayout" class="alert alert-danger border-danger">
      <i class="fas fa-exclamation-triangle"></i> ขณะนี้บริษัทเปิดใช้อัตราจ่ายพิเศษ ซึ่งจะทำให้เรทจ่าย และส่วนลดปรับตามตารางอัตราจ่ายข้างล่างนี้ทั้งหมด <u>ยกเว้นเลขอั้น</u><br /> (<router-link to="/help">คู่มือการใช้งาน (อัตราจ่าย และเลขอั้น)</router-link>)
    </div>

    <div class="page-header mb-1">
      <h4>เลขอั้น</h4>
    </div>

    <div v-if="roundId" class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">
          <i class="fas fa-info-circle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะ</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบด้านบน)</small>
        </h5>
      </div>
      <div class="card-body">

        <h5 class="mb-1"><i class="fas fa-star"></i> ราคาจ่าย</h5>
        <table class="table table-bordered mb-0" :class="[{'table-success': isCustomPayout}, {'table-pp': !isCustomPayout}]">
          <thead>
            <tr>
              <th colspan="2" class="text-center">อัตราจ่าย </th>
              <th width="11%" v-for="bet in marketOpenBets" :key="bet.code" class="text-center">{{ bet.text }}</th>
            </tr>
          </thead>
          <tbody v-for="rate in rates" :key="rate.rateId">
            <tr>
              <td rowspan="2" class="text-center">
                <h6 class="mb-0">{{rate.rateTitle}}</h6>
              </td>
              <td width="10%" class="text-center">จ่าย (บาท)</td>
              <td v-for="bet in marketOpenBets" :key="bet.code" class="text-center" :class="[{'bg-secondary':!rate.rates[bet.code].isAvailable}]">
                <span v-if="rate.rates[bet.code].isAvailable">{{ rate.rates[bet.code].pay | amountFormat }}</span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <td width="10%" class="text-center">ส่วนลด (%)</td>
              <td v-for="bet in marketOpenBets" :key="bet.code" class="text-center" :class="[{'bg-secondary':!rate.rates[bet.code].isAvailable}]">
                <span v-if="rate.rates[bet.code].isAvailable">{{ rate.rates[bet.code].discount | amountFormat }}</span>
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt-4">
          <h5>
            <span class="mr-2">เลขอั้น</span>
            <span class="text-primary mr-2">{{ roundName }}</span>
            <span class="text-secondary mr-2" style="text-decoration: underline;">(เฉพาะงวด)</span>
            <span>วันที่ <span class="text-primary">{{ roundDate }}</span></span>
            <small class="text-secondary ml-2">(เปลี่ยนได้ที่แถบด้านบน)</small>
          </h5>
          <RoundSetNumbers :data="setNumbers" />
        </div>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import RoundService from "@/services/RoundService"
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import { marketOpenBets } from "@/configs/market.config"
import LimitNumbers from './components/LimitNumbers'
import RoundSetNumbers from './components/RoundSetNumbers'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundLimitNumbers',
  components: {
    LimitNumbers,
    RoundSetNumbers
  },
  data() {
    return {
      data: null
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      // return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
      return `หวย${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    marketOpenBets() {
      return marketOpenBets
    },
    rates() {
      if(!this.data)
        return []

      return this.data.payouts
    },
    setNumbers() {
      if(!this.data)
        return null

      return this.data.round.setNumbers
    },
    isCustomPayout() {
      return this.data?.isCustomPayout
    }
  },
  watch: {
    roundId() {
      this.loadRoundData()
    }
  },
  methods: {
    loadRoundData() {
      if(!this.roundId)
        return

      RoundService.getRoundLimitNumbers(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.loadRoundData()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        vertical-align: middle;
        line-height: 1;
      }
    }
  }
}
</style>
